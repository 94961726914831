import * as THREE from "three";
import vertexShader from "../shaders/vertex.glsl";
import fragmentShader from "../shaders/fragment.glsl";
import gsap from "gsap";

export default class HeroMesh {
  constructor(scene, data, type) {
    this.data = data;
    this.type = type;
    this.scene = scene;
    this.geometry;
    this.material;
    this.mesh;
    this.uniforms;
  }

  createMesh() {
    this.uniforms = {};
    this.uniforms.uTime = { value: 0 };
    this.uniforms.textureDir = { value: this.data.textureDir };
    this.uniforms.rX = { value: this.data.rX };
    this.uniforms.rY = { value: this.data.rY };
    this.uniforms.uTexture = { value: this.data.uTexture };
    this.uniforms.vertexON = { value: this.data.vertexON };
    this.uniforms.bgColor = { value: this.data.bgColor };

    // Geometry
    if (this.type == "type1") {
      this.geometry = new THREE.TorusKnotGeometry(11, 5, 150, 75, 3, 2);
    } else if (this.type == "type2") {
      this.geometry = new THREE.SphereGeometry(13, 82, 82);
    } else if (this.type == "type3") {
      this.geometry = new THREE.TorusGeometry(12, 10, 50, 50);
    } else if (this.type == "type4") {
      this.geometry = new THREE.BoxGeometry(60, 25, 25);
    } else if (this.type == "type5") {
      this.geometry = new THREE.OctahedronGeometry(20, 0);
    }

    // Material
    this.material = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader,
      uniforms: this.uniforms,
      side: THREE.DoubleSide,
    });

    // Mesh
    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.mesh.position.x = this.data.positionX;
    this.mesh.position.y = this.data.positionY;
    this.mesh.position.z = this.data.positionZ;
    this.mesh.rotation.x = this.data.rotationX;
    this.mesh.rotation.y = this.data.rotationY;
    this.mesh.rotation.z = this.data.rotationZ;
    this.mesh.visible = false;
    this.scene.add(this.mesh);
    this.mesh.position.x = 100;
  }

  updateColor(col) {
    this.data.bgColor = col;
    this.uniforms.bgColor = { value: col };
  }
  animateIn() {
    this.mesh.visible = true;
    this.mesh.position.x = 60;
    this.mesh.rotation.y = this.data.rotationY - 3;
    gsap.to(this.mesh.position, {
      duration: 1,
      x: this.data.positionX,
    });
    gsap.to(this.mesh.rotation, {
      duration: 1,
      y: this.data.rotationY,
    });
  }
  animateOutInit() {
    this.mesh.visible = false;
  }
  animateOut() {
    gsap.to(this.mesh.position, {
      duration: 1.5,
      /*   ease: "back.in(1.1)", */
      x: -60,
      onComplete: () => {
        this.mesh.visible = false;
      },
    });
    gsap.to(this.mesh.rotation, {
      duration: 1,
      y: this.data.rotationY + 3,
    });
  }

  update() {
    if (this.material.uniforms) {
      this.material.uniforms.uTime.value = this.data.uTime.getElapsedTime();
    }
  }
}
